<!--清洁能源-->
<template>
  <div id="cleanEnergyAnalysis" v-loading="loading" :element-loading-text="$t('cleanEnergyAnalysis.a1')">
    <!-- 头部 -->
    <div class="header">
      <my-PageTitle>{{ $t('cleanEnergyAnalysis.a2') }}</my-PageTitle>
    </div>
    <!-- 内容区域 -->
    <div class="main">
      <!-- 查询栏 -->
      <div class="main-condition">
        <div class="main-condition-select">
            <!-- 能源类型 -->
            <a-tree-select
                v-model="groupSelect"
                :tree-data="cleanEnergyGroup"
                :treeDefaultExpandAll="true"
                :replaceFields="{ label: 'name', value: 'no', children: 'children' }"
                :dropdown-style="{ maxHeight: '350px', overflow: 'auto' }"
                style="width:140px;margin-right:15px"
                @select="treeSelect"
            />
            <!-- 年、月、日选择 -->
            <a-select v-model="yearMonthDaySelect" style="width:80px;margin-right:15px">
                <a-select-option v-for="(item,index) in rptKinds" :value="item.no" :key="index">
                    {{ item.text }}
                </a-select-option>
            </a-select>
            <!-- 数据类型选择 -->
            <a-select v-model="dataType" style="width:120px;margin-right:15px">
                <a-select-option :value="1">
                    {{ $t('cleanEnergyAnalysis.a3') }}
                </a-select-option>
                <a-select-option :value="2">
                    {{ $t('cleanEnergyAnalysis.a4') }}
                </a-select-option>
                <a-select-option :value="3" :disabled="dataTypeDisabled">
                    {{ $t('cleanEnergyAnalysis.a5') }}                               
                </a-select-option>
            </a-select>
          <div class="date">
            <!-- 年条件数据值选择 -->
            <transition name="slide-fade">
                <div class="date-select" v-if="yearMonthDaySelect == 1">
                    <a-select v-model="yearDateSelect" style="width: 100px;margin-right:15px">
                        <a-select-option v-for="(item,index) in yearData" :key="index" :value="item.value">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                    <a-checkbox v-model="customDate" style="margin-right:7px">
                        {{ $t('cleanEnergyAnalysis.a6') }}
                    </a-checkbox>
                    <a-select v-model="monthSelect" style="width: 120px" :disabled="!customDate">
                        <a-select-option v-for="(item,index) in monthList" :value="item.no" :key="index">
                           {{ item.text }}
                        </a-select-option>
                    </a-select>
                </div>
            </transition>
            <!-- 月条件数据值选择 -->
            <transition name="slide-fade">
                <div class="date-select" v-if="yearMonthDaySelect == 2">
                    <a-month-picker v-model="monthDateSelect" style="width:140px" :allowClear="false" />
                </div>
            </transition>
            <!-- 日条件数据值选择 -->
            <transition name="slide-fade">
                <div class="date-select" v-if="yearMonthDaySelect == 3">
                    <a-date-picker v-model="dayDateSelect" style="width:140px" :allowClear="false" />
                </div>
            </transition>
          </div>
        </div>
        <div>
          <!-- 查询按钮及设置按钮 -->
        <a-button-group>
          <a-button type="primary" ghost @click="query">{{ $t('cleanEnergyAnalysis.a7') }}</a-button>
          <a-button type="primary" @click="CSVexport" :loading="butLoading">{{ $t('cleanEnergyAnalysis.a8') }}</a-button>
        </a-button-group>
        </div>
      </div>
      <!-- 图表 -->
      <div class="main-echarts">
        <div class="empty" v-if="show == 0">
            <a-empty>
                <span class="explain">
                    {{ $t('cleanEnergyAnalysis.a9') }}
                </span>
            </a-empty>
        </div>
        <div class="main-echarts-left" v-if="show == 1">
          <div class="tow">
            <div id="CEA-echarts-1" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="tow">
            <div id="CEA-echarts-2" :style="{ width: '100%', height: '100%' }"></div>
          </div>
        </div>
        <div class="main-echarts-left" v-if="show == 2">
          <div class="three">
            <div id="CEA-echarts-1" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="three">
            <div id="CEA-echarts-2" :style="{ width: '100%', height: '100%' }"></div>
          </div>
          <div class="three">
            <div id="CEA-echarts-3" :style="{ width: '100%', height: '100%' }"></div>
          </div>
        </div>
        <div class="main-echarts-right" v-if="show !== 0">
          <div id="CEA-echarts-0" :style="{ width: '100%', height: '100%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../../components/Title/PageTitle.vue'
import { EleResize } from '../../utils/esresize'
import { getCleanEnergy,getCleanEnergyCSV } from '../../api/energy'
import moment from 'moment'

export default {
  name:'cleanEnergyAnalysis',
  components:{
    'my-PageTitle':PageTitle,
    },
  data(){
    return{
        loading:false,
        cleanEnergyGroup:[],
        groupSelect:[],
        fiscalYear:'',
        monthList:[],
        rptKinds:[],
        yearData:[],
        yearMonthDaySelect:'1',
        yearDateSelect:'',
        monthDateSelect:moment(),
        dayDateSelect:moment(),
        dataType:1,
        customDate:false,
        monthSelect:'',
        pieOption1:{
                color:['#62d9ad','#5aaef3'],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: "scroll",
                    bottom: "0%",
                    left: "center",
                },
                series: [
                    {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    bottom:15,
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    data: []
                    }
                ]
        },
        pieOption2:{
                color:['#62d9ad','#ff934d'],
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: "scroll",
                    bottom: "0%",
                    left: "center",
                },
                series: [
                    {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    bottom:15,
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    data: []
                    }
                ]
        },
        pieOption3:{
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    type: "scroll",
                    bottom: "0%",
                    left: "center",
                },
                series: [
                    {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    bottom:15,
                    itemStyle: {
                        borderRadius: 5,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    data: []
                    }
                ]
        },
        gridOption:{
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'shadow' 
              }
          },
          legend: {
            type: "scroll"
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          xAxis: {
              type: 'category',
              data: [],
              axisLabel:{
                interval:0,
                rotate:25
              }
          },
          yAxis: [
              {
                  type: 'value',
                  position: 'left',
                  name:this.$t('cleanEnergyAnalysis.a10'),
                  axisLine: {
                      show: true,
                  },
              },
              {
                  type: 'value',
                  position: 'right',
                  name:'',
                  axisLine: {
                      show: true,
                  },
              },
          ],
          series: []
        },
        echarts1:undefined,
        echarts2:undefined,
        echarts3:undefined,
        echarts4:undefined,
        show:0,
        item:[
            '00:01',
            '00:02',
            '00:03',
            '00:04',
            '00:05',
            '00:06',
            '00:07',
            '00:08',
            '00:09',
            '00:10',
            '00:11',
            '00:12',
            '00:00',
            '00:13',
            '00:14',
            '00:15',
            '00:16',
            '00:17',
            '00:18',
            '00:19',
            '00:20',
            '00:21',
            '00:22',
            '00:23',
            '00:24'
        ],
        butLoading:false,
        dataTypeDisabled:true
    }
  },
    // 清洁能源页面数据初始化   
    mounted(){
        this.loading = true
        const data = {
            action:0,
            clientId: this.$store.getters.clientId,
            sitegrId: this.$store.getters.sitegrId
        }
        getCleanEnergy(data)
        .then(res=>{
            console.log(res)
            const { data:{ cleanEnergyGroup,fiscalYear,monthList,rptKinds,yearData } } = res
            this.cleanEnergyGroup = cleanEnergyGroup
            this.fiscalYear = fiscalYear
            this.monthList = monthList
            this.rptKinds = rptKinds
            this.yearData = yearData
            this.groupSelect = cleanEnergyGroup[0].no
            this.yearDateSelect = fiscalYear
            this.monthSelect = monthList[0].no
            this.loading = false
        })
        .catch(err=>{
            console.log(err)
            this.loading = false
        })
    },
    methods:{
        moment,
        // 清洁能源数据查询功能
        query(){
            console.log(this.groupSelect)
            this.loading = true
            const clientId = this.$store.getters.clientId
            const sitegrId = this.$store.getters.sitegrId
            const cleanEnergyGroupId = this.groupSelect
            const reportType = this.yearMonthDaySelect
            const dataType = this.dataType
            const year = this.yearDateSelect
            const defaultStatistic = this.customDate ? 1 : 0
            const month = this.monthSelect
            const monthDateSelect = this.monthDateSelect.format('YYYY/MM')
            const dayDateSelect = this.dayDateSelect.format('YYYY/MM/DD')
            const data = {
                action:1,
                clientId,
                sitegrId,
                cleanEnergyGroupId,
                reportType,
                dataType,
            }
            if(dataType == 1){
                this.gridOption.yAxis[1].name = this.$t('cleanEnergyAnalysis.a12')
            }else if(dataType == 2){
                this.gridOption.yAxis[1].name = this.$t('cleanEnergyAnalysis.a11')
            }else if(dataType == 3){
                this.gridOption.yAxis[1].name = ''
            }
            if(reportType == '1'){
                data.year = year
                data.defaultStatistic = defaultStatistic
                if(defaultStatistic == 1){ data.month = month}
            }else if(reportType == '2'){
                data.date = monthDateSelect
            }else{
                data.date = dayDateSelect
            }
            console.log(data)
            getCleanEnergy(data)
            .then(res => {
                console.log(res)
                const { data:{ chartData,pieData1,pieData2,pieData3 }} = res
                this.pieOption1.series[0].data = pieData1
                this.pieOption2.series[0].data = pieData2
                this.pieOption3.series[0].data = pieData3
                this.gridOption.series = chartData
                if(reportType == '1'){
                    const length = chartData[0].data
                    if(defaultStatistic == 0){
                        const monthData = length.map((item,index)=>{ 
                            if((index + 4)<= 12){ 
                                return `${year}/${index + 4}`
                            }else{ 
                                return `${year + 1}/${4 - (12 - index)}`
                            } 
                        })
                        this.gridOption.xAxis.data = monthData
                    }else{
                        const monthData = length.map((item,index)=>{ 
                            if((index + Number(month))<= 12){ 
                                return `${year}/${index + Number(month)}`
                            }else{ 
                                return `${year + 1}/${Number(month) - (12 - index)}`
                            } 
                        })
                        this.gridOption.xAxis.data = monthData
                    }
                }else if(reportType == '2'){
                    const length = chartData[0].data
                    const dayData = length.map((item,index)=>{ return index + 1})
                    this.gridOption.xAxis.data = dayData
                }else{
                    this.gridOption.xAxis.data = this.item
                }
                if(dataType !== 3){
                    this.show = 1
                    this.$nextTick(function () {
                        if(this.echarts1 !== undefined){ this.echarts1.dispose() }
                        if(this.echarts2 !== undefined){ this.echarts2.dispose() }
                        if(this.echarts4 !== undefined){ this.echarts4.dispose() }
                        this.drawPie1()
                        this.drawPie2()
                        this.drawGrid()
                    })
                }else{
                    this.show = 2
                    this.$nextTick(function () {
                        if(this.echarts1 !== undefined){ this.echarts1.dispose() }
                        if(this.echarts2 !== undefined){ this.echarts2.dispose() }
                        if(this.echarts3 !== undefined){ this.echarts3.dispose() }
                        if(this.echarts4 !== undefined){ this.echarts4.dispose() }
                        this.drawPie1()
                        this.drawPie2()
                        this.drawPie3()
                        this.drawGrid()
                    })
                }
                this.loading = false
            })
            .catch(err => {
                console.log(err)
                this.loading = false
            })
        },
        // 环形图（第一个）绘制
        drawPie1() {
          let myChart = this.$echarts.init(document.getElementById('CEA-echarts-1'))
          let resizeDiv = document.getElementById('CEA-echarts-1')
          this.echarts1 = myChart
          myChart.setOption(this.pieOption1)
          let listener = () => {
              myChart.resize()
          }
          EleResize.on(resizeDiv, listener)
        },
        // 环形图（第二个）绘制
        drawPie2(){
          let myChart = this.$echarts.init(document.getElementById('CEA-echarts-2'))
          let resizeDiv = document.getElementById('CEA-echarts-2')
          this.echarts2 = myChart
          myChart.setOption(this.pieOption2)
          let listener = () => {
              myChart.resize()
          }
          EleResize.on(resizeDiv, listener)
        },
        // 环形图（第三个）绘制
        drawPie3(){
          let myChart = this.$echarts.init(document.getElementById('CEA-echarts-3'))
          let resizeDiv = document.getElementById('CEA-echarts-3')
          this.echarts3 = myChart
          myChart.setOption(this.pieOption3)
          let listener = () => {
              myChart.resize()
          }
          EleResize.on(resizeDiv, listener)
        },
        // 折柱图绘制
        drawGrid(){
          let myChart = this.$echarts.init(document.getElementById('CEA-echarts-0'))
          let resizeDiv = document.getElementById('CEA-echarts-0')
          this.echarts4 = myChart
          myChart.setOption(this.gridOption)
          let listener = () => {
              myChart.resize()
          }
          EleResize.on(resizeDiv, listener)
        },
        // csv导出
        CSVexport(){
            this.butLoading = true
            const clientId = this.$store.getters.clientId
            const sitegrId = this.$store.getters.sitegrId
            const cleanEnergyGroupId = this.groupSelect
            const reportType = this.yearMonthDaySelect
            const dataType = this.dataType
            const year = this.yearDateSelect
            const defaultStatistic = this.customDate ? 1 : 0
            const month = this.monthSelect
            const monthDateSelect = this.monthDateSelect.format('YYYY/MM')
            const dayDateSelect = this.dayDateSelect.format('YYYY/MM/DD')
            const data = {
                action:1,
                clientId,
                sitegrId,
                cleanEnergyGroupId,
                reportType,
                dataType,
            }
            if(reportType == '1'){
                data.year = year
                data.defaultStatistic = defaultStatistic
                if(defaultStatistic == 1){ data.month = month}
            }else if(reportType == '2'){
                data.date = monthDateSelect
            }else{
                data.date = dayDateSelect
            }
            getCleanEnergyCSV(data)
            .then(res=>{
                console.log(res)
                const date = moment().format('YY-HH-DD')
                let url = window.URL.createObjectURL(res)
                let link = document.createElement("a")
                link.style.display = "none"
                link.href = url
                link.setAttribute("download", `${this.$t('cleanEnergyAnalysis.a2')}${date}.zip`)
                document.body.appendChild(link)
                link.click()
                this.butLoading = false
            })
            .catch(err=>{
                console.log(err)
                this.butLoading = false
            })
        },
        // 树形选择
        treeSelect(value, node){
            const level = node.dataRef.level
            if(level == 1){
                this.dataTypeDisabled = true
            }else{
                this.dataTypeDisabled = false
            }
        }
    }
}
</script>

<style scoped>
#cleanEnergyAnalysis{
  width: 100%;
  height: 100%;
}
.header{
  height: 50px;
}
.main{
  height: calc(100% - 50px);
  padding: 20px;
}
.main-condition{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.main-echarts{
  height: calc(100% - 47px);
  display: flex;
}
.main-condition-select{
  display: flex;
}
.main-echarts-left{
  width: 30%;
}
.main-echarts-right{
  width: 70%;
}
.tow{
  height: 50%;
}
.three{
  height: 33.3%;
}
.empty{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.explain{
    font-size: 20px;
    color: #7682ce;
}
.date{
    position: relative;
}
.date-select{
    width: 500px;
    position: absolute;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>